import * as React from "react"
import Helmet from 'react-helmet'

import Layout from '../components/layout.js'
import Contact from '../components/contact.js'


const ServicePage = () => {
  return (
    <Layout>

      <Helmet>
        <title>Freelance WordPress Developer Brighton | Billy Dawson</title>
        <meta property="description" content="Using modern tools and development techniques I create, maintain and alter all kinds of WordPress websites from simple brochures to e-commerce." />
      </Helmet>

      <div className="big-header yellow">
        <div className="wrap container">
          <div className="row">
            <div className="col-xs-11 col-sm-8 col-md-6 col-lg-5">
              <h1>Freelance WordPress Developer Brighton</h1>
              <p>I create bespoke, lightweight WordPress sites from the ground up. No bulky code or heavy plugins.</p>
              <p className="muted">Using modern tools to create minimal, effective code the sites I build have vastly increased performance both in site speed and search engines.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="wrap container">
          <div className="row title-row">
            <div className="col-xs-12">
              <h2>Why choose WordPress?</h2>
            </div>
          </div>

          <div className="row list-items">

            <div className="col-xs-12 col-md-4">
              <p>Running 30% of the internet WordPress is the most used CMS, and it has been for some time.</p>
              <p className="muted">If customers are trying to get to your website and it’s just not working, that reflects on your brand. This isn’t a problem at all with a well built WordPress site.</p>
            </div>

            <div className="col-xs-12 col-md-4">
              <p>It’s so simple to use. Adding, altering and removing your content is extremely straightforward.</p>
              <p className="muted">You don’t want to be wasting your time fighting against a fiddly CMS — I build sites so you have the power over your content. Making it easier for you to concentrate on what really matters.</p>
            </div>

            <div className="col-xs-12 col-md-4">
              <p>You can create any kind of website that you want, with the flexibility to change it without large costs.</p>
              <p className="muted">With so many users there’s great support for anything you’d like to do with your website, from a simple blog all the way to an international ecommerce business.</p>
            </div>

          </div>
        </div>
      </div>

      <div className="section">
        <div className="wrap container">
          <div className="row title-row">
            <div className="col-xs-12">
              <h2>How I can help with WordPress development</h2>
            </div>
          </div>

          <div className="row list-items">

            <div className="col-xs-12 col-md-4">
              <p>Designing + building a WordPress site from the ground up.</p>
              <p className="muted">If you’re looking for a new website and have decided WordPress is the way to go, I can take you through the process from design to launch.</p>
            </div>

            <div className="col-xs-12 col-md-4">
              <p>Making alterations and additions to an existing WordPress site.</p>
              <p className="muted">Already got a site you love but could do with a few odds and ends sorting out? I’m more than happy to get that done for you.</p>
            </div>

            <div className="col-xs-12 col-md-4">
              <p>Got your designs already and just need someone to put it together?</p>
              <p className="muted">I'm more than happy to take on a site that has had the design work completed but needs building, already half way there!</p>
            </div>

          </div>
        </div>
      </div>

      <Contact colour="green" />

    </Layout>
  )
}

export default ServicePage
